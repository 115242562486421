import React from 'react';
import { Link } from 'react-router-dom';
// import headerLogo from '../assets/img/logo/logoJiva.png';

const Header = () => {
  return (
    <header className="header">
      <div className="container">
        <div className="header__row">
          {/* <img src={headerLogo} alt="" /> */}
          <p className="header__row-text">
            Приветствуем в магазине «Jiva» (Читается, как «джива», <br /> а само
            слово означает «жизнь/живой»)<br/> А «живые» наши продукты потому что: 
            <Link className="header__row-link" to="/aboutus">
            <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <defs></defs>
                  <title />
                  <g id="arrow-right">
                    <line className="cls-1" x1="29.08" x2="3.08" y1="16" y2="16" />
                    <line className="cls-1" x1="29.08" x2="25.08" y1="16" y2="21" />
                    <line className="cls-1" x1="29.08" x2="25.08" y1="16" y2="11" />
                  </g>
                </svg>
            </Link>
          </p>
          {/* <a href="#!" className="button button--header">
          О нас
        </a> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
