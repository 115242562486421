import React from 'react';

const Cookie = () => {
  return (
    <div className="container">
      <div className="cookie">
        <h2 className="cookie__title">
          Политика конфиденциальности и обработки персональных данных интернет магазина{' '}
          <span>«Jiva»</span>
        </h2>
        <div className="cookie__terms">
          <h3 className="cookie__terms-title">Термины и определения:</h3>
          <p>
            <span className="yep">Оператор</span> — индивидуальный предприниматель Ламанова Юлия
            Викторовна (ИНН 420543086580, ОГРНИП 322420500020732)
          </p>
          <p>
            <span className="yep">Сайт</span> — совокупность программных средств, контента, баз
            данных, объединенную единым дизайном и названием «Jiva» и обеспечивающую возможность
            покупки товаров.
          </p>
          <p>
            <span className="yep">Субъект персональных данных (Заказчик)</span> — физическое лицо,
            заполнившее форму-заказа товара и совершившее акцепт настоящей Политики.
          </p>
          <p>
            <span className="yep">Прочие термины и определения</span> в Политике используются в
            соответствии с их значениями, как они определены в ФЗ-152 «О персональных данных».
          </p>
        </div>
        <div className="cookie__text">
          <h3 className="cookie__text-title">Введение:</h3>
          <p>
            Настоящая Политика конфиденциальности и обработки персональных данных (далее — Политика)
            действует в отношении всей информации, которую Оператор может получить о Заказчике во
            время использования им услуг Оператора, а также при коммуникации с Оператором в любой
            форме, в соответствии с данной Политикой.
          </p>
          <p>
            Отправка формы-заказа на Сайте означает безоговорочное согласие Заказчика с настоящей
            Политикой и указанными в ней условиями обработки его персональных данных.
          </p>
          <p>
            В случае несогласия с этими условиями Заказчик должен воздержаться от пользования
            услугами Оператора.
          </p>
          <p>
            Политика действует бессрочно после утверждения и до ее замены новой версией.Политика
            является общедоступным документом, декларирующим концептуальные основы деятельности
            Оператора при обработке и защите персональной информации и персональных данных
            Заказчиков Оператора.
          </p>
          <p className="yep">
            1. Персональная информация Заказчика, которую получает и обрабатывает Оператор:
          </p>
          <p>
            1.1. В рамках настоящей Политики под персональной информацией и персональными данными
            Заказчика, которые собирает Оператор понимаются: <br />
            1.1.1. Персональная информация, которую Заказчик предоставляет о себе самостоятельно при
            отправке формы-заказа товара на Сайте.
          </p>
          <p>
            1.1.2. Данные, которые автоматически передаются Оператору в процессе использования Сайта
            с помощью установленного на устройстве Заказчика программного обеспечения, в том числе
            IP-адрес, информация из cookie, информация о браузере Заказчика (или иной программе, с
            помощью которой осуществляется доступ к Оператору), время доступа, адрес запрашиваемой
            страницы.
          </p>
          <p>
            1.2. Настоящая Политика применима только к Оператору. Владелец Оператора не контролирует
            и не несет ответственность за сайты или приложения третьих лиц, на которые Заказчик
            может перейти по ссылкам, размещенным на Сайте Оператора, в том числе за обрабатываемую
            третьими лицами информацию о Пользователе.
          </p>
          <p>
            1.3. За исключением случаев идентификации Заказчиков по основаниям и в порядке,
            предусмотренным применимыми соглашениями Оператора и Заказчика, Оператор не проверяет
            достоверность персональной информации, предоставляемой Заказчиком, и не осуществляет
            контроль за их дееспособностью. Однако Оператор исходит из того, что Заказчик
            предоставляет достоверную и достаточную персональную информацию.
          </p>
          <p className="yep">2. Цели сбора и обработки персональной информации Заказчика:</p>
          <p>
            2.1. Оператор собирает и хранит только ту персональную информацию, которая необходима
            для выполнения Оператором договора с Заказчиками, за исключением случаев, когда
            законодательством предусмотрено обязательное хранение персональной информации в течение
            определенного законом срока.
          </p>
          <p>
            2.2. Персональную информацию Заказчика Оператор может использовать в следующих целях:{' '}
            <br />
            2.2.3. Проведения статистических и иных исследований, на основе обезличенных <br />
            данных; 2.2.4. Публикации в социальных сетях и на Сайте Оператора фото- и
            видеоматериалов с участием Заказчика, сделанных Оператором во время покупки товара.
          </p>
          <p>
            2.2.5. Проведения маркетинговых акций для Заказчиков, в том числе в целях
            распространения предложений об участии в акциях и получения предусмотренных акцией
            призов/вознаграждений; распространения рекламно-информационных материалов по сетям
            электросвязи, в том числе посредством использования телефонной, факсимильной, подвижной
            радиотелефонной связи, или путем прямых контактов; таргетирования рекламных материалов и
            иной информации, доводимой до сведения Заказчика;
          </p>
          <p>
            2.2.6. Направления Заказчикам новостных материалов о деятельности Оператора; <br />
            2.3. Оператор не осуществляет обработку биометрических персональных данных Заказчика
            (сведения, которые характеризуют физиологические и биологические особенности человека,
            на основании которых можно установить его личность)
          </p>
          <p>
            2.4. Оператор не выполняет обработку специальных категорий персональных данных,
            касающихся расовой, национальной принадлежности, политических взглядов, религиозных или
            философских убеждений, состояния здоровья, интимной жизни.
          </p>
          <p>
            2.5. Оператор не производит трансграничную (на территорию иностранного государства
            органу власти иностранного государства, иностранному физическому лицу или иностранному
            юридическому лицу) передачу персональных данных Заказчика.
          </p>
          <p>
            2.6. Оператором не принимаются решения, порождающие юридические последствия в отношении
            субъектов персональных данных или иным образом затрагивающие их права и законные
            интересы, на основании исключительно автоматизированной обработки их персональных
            данных.
          </p>
          <p className="yep">
            3. Условия обработки персональной информации Заказчиков и её передачи третьим лицам:
          </p>
          <p>
            3.1. Оператор хранит персональную информацию Заказчика в соответствии с настоящей
            Политикой, условиями оказания конкретных услуг и внутренними регламентами Оператора.
          </p>
          <p>
            3.2. В отношении персональной информации Заказчика обеспечивается ее конфиденциальность.
          </p>
          <p>
            3.3. Владелец Оператора вправе передать персональную информацию Пользователей третьим
            лицам в следующих случаях: <br /> 3.3.1. Заказчик выразил свое согласие на такие
            действия;
          </p>
          <p>
            3.3.2. Передача необходима для использования определенной услуги Оператора, в том числе
            для исполнения определенного распоряжения Заказчика;
          </p>
          <p>
            3.3.3. Передача предусмотренная российским или иным применимым законодательством в
            рамках установленной законодательством процедуры;
          </p>
          <p>
            3.3.4. Передача происходит в рамках продажи или иной передачи бизнеса (полностью или в
            части), при этом к приобретателю переходят все обязательства по соблюдению условий
            настоящей Политики применительно к полученной им персональной информации;
          </p>
          <p>
            3.3.5. В целях обеспечения возможности защиты прав и законных интересов Оператора или
            третьих лиц в случаях, когда у Оператора имеются достаточные основания полагать, что
            Заказчик нарушает условия применимого соглашения (договора) с Оператором и/или
            требования применимого законодательства. <br /> 3.4. При обработке персональных данных
            Заказчиков Оператор руководствуется Федеральным законом «О персональных данных».
          </p>
          <p className="yep">
            4. Изменение и удаление Заказчиком персональной информации или персональных данных:
          </p>
          <p>
            4.1. В соответствии с требованиями законодательства на Оператора может быть возложена
            обязанность осуществлять обработку / хранение полученной при пользовании услугами
            Оператора персональной информации Заказчика. Такая обработка / хранение осуществляется
            Оператором в случаях, по основаниям и в течение сроков, установленных законодательством.
          </p>
          <p>
            4.2. Оператор прекращает обработку персональной информации и персональных данных
            Пользователей: — при наступлении условий прекращения обработки персональных данных или
            по истечении установленных сроков; <br /> — по достижении целей их обработки либо в
            случае утраты необходимости в достижении этих целей; <br /> — по требованию Заказчика,
            если обрабатываемые персональные данные являются незаконно полученными или не являются
            необходимыми для заявленной цели обработки; <br /> — в случае выявления неправомерной
            обработки персональных данных, если обеспечить правомерность обработки невозможно;{' '}
            <br /> — по истечении срока действия согласия Заказчика на обработку персональных данных
            или в случае отзыва Заказчиком такого согласия, если для обработки персональных данных
            не будет иных правовых оснований, предусмотренных законодательством Российской
            Федерации; <br /> — в случае прекращения работы Оператора. <br /> 4.3. Права Заказчика
            как субъекта персональных данных <br />
            4.3.1. Заказчик имеет право на получение сведений об обработке его персональных данных
            Оператором.
          </p>
          <p>
            4.3.2. Заказчик вправе требовать от Оператора уточнения этих персональных данных, их
            блокирования или уничтожения в случае, если они являются неполными, устаревшими,
            неточными, незаконно полученными или не могут быть признаны необходимыми для заявленной
            цели обработки, а также принимать предусмотренные законом меры по защите своих прав.
          </p>
          <p>
            4.3.3. Право Заказчика на доступ к его персональным данным может быть ограничено в
            соответствии с федеральными законами, в том числе, если доступ Заказчика к его
            персональным данным нарушает права и законные интересы третьих лиц.
          </p>
          <p>
            4.3.4. Для реализации и защиты своих прав и законных интересов Заказчик имеет право
            обратиться к Оператору. Оператор рассматривает любые обращения и жалобы со стороны
            Заказчика, тщательно расследует факты нарушений и принимает все необходимые меры для их
            немедленного устранения, наказания виновных лиц и урегулирования спорных и конфликтных
            ситуаций в досудебном порядке.
          </p>
          <p>
            4.3.5. Заказчик вправе обжаловать действия или бездействие Оператора путем обращения в
            уполномоченный орган по защите прав субъектов персональных данных.
          </p>
          <p>
            4.3.6. Заказчик имеет право на защиту своих прав и законных интересов, в том числе на
            возмещение убытков и/или компенсацию морального вреда в судебном порядке.
          </p>
          <p className="yep">
            5. Меры, применяемые для защиты персональной информации Пользователей:
          </p>
          <p>
            5.1. Оператор принимает необходимые и достаточные организационные и технические меры для
            защиты персональной информации Заказчика от неправомерного или случайного доступа,
            уничтожения, изменения, блокирования, копирования, распространения, а также от иных
            неправомерных действий с ней третьих лиц.
          </p>
          <p>
            5.2. Защиту и обработку персональных данных Заказчика Оператор производит в соответствии
            с требованиями Федерального закона «О персональных данных», Постановления Правительства
            РФ «Об утверждении требований к защите персональных данных при их обработке в
            информационных системах персональных данных» No 1119 от 01 ноября 2012 г., Постановления
            Правительства РФ от 15 сентября 2008 г. No687 «Об утверждении Положения об особенностях
            обработки персональных данных, осуществляемой без использования средств автоматизации».
          </p>
          <p>
            5.3. При обработке персональных данных Оператор обеспечивает их безопасность и принимает
            необходимые организационные и технические меры для защиты персональных данных от
            неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования,
            копирования, распространения персональных данных, а также от иных неправомерных
            действий, путем установления в отношении таких данных режима конфиденциальности и
            контроля за его соблюдением, а также путем внедрения дополнительных мер защиты,
            реализующих требования законодательства Российской Федерации, стандартов и внутренних
            организационно-распорядительных документов Оператора. <br /> Реализуются следующие
            требования к защите персональных данных: <br /> — определение угроз безопасности
            персональных данных при их обработке в информационных системах Оператора; <br /> —
            обнаружение фактов несанкционированного доступа к персональным данным и принятие мер;{' '}
            <br /> — восстановление персональных данных, модифицированных или уничтоженных
            вследствие несанкционированного доступа к ним; <br /> — установление правил доступа к
            персональным данным, обрабатываемым в информационной системе;
          </p>
          <p className="yep">6. Изменение настоящей Политики. Применимое законодательство</p>
          <p>
            6.1. Оператор вправе изменить и/или дополнить настоящую Политику путем опубликования
            Политики в новой редакции на Сайте Оператора по адресу{' '}
            <span className="yep">
              <a href="https://jivalab.ru/" rel="noreferrer">
                https://jivalab.ru/
              </a>
            </span>
            . Принятием Заказчиком такого предложения являются конклюдентные действия по пользованию
            услугами Оператора на новых условиях.
          </p>
          <p>
            6.2. К настоящей Политике и отношениям между Заказчиком и Оператором, возникающим в
            связи с применением Политики, подлежит применению законодательство Российской Федерации.
          </p>
          <span className="cookie__text-footer yep">
            Политика jiva в отношении использования файлов «cookie»
          </span>
        </div>
      </div>
    </div>
  );
};

export default Cookie;
